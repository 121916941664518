import { useState } from "react";
import { FiEye, FiEyeOff } from "react-icons/fi";
import { Input, Label } from "reactstrap";
import React from "react";
import { useIntl } from "react-intl";

const PasswordInput = ({ errors, register }) => {
  const [, setPassword] = useState("");
  const [type, setType] = useState("password");
  const [icon, setIcon] = useState(FiEyeOff);
  const intl = useIntl();

  const handleToggle = () => {
    if (type === "password") {
      setIcon(FiEye);
      setType("text");
    } else {
      setIcon(FiEyeOff);
      setType("password");
    }
  };

  return (
    <div className={"password-input-group w-100"}>
      <Label for={"password"} className={"label-required"}>
        {intl.formatMessage({
          id: "auth.login.password",
        })}
      </Label>
      <div className={"password-input"}>
        <Input
          type={type}
          name="password"
          id={"password"}
          placeholder={intl.formatMessage({
            id: "auth.login.password",
          })}
          onChange={(e) => setPassword(e.target.value)}
          autoComplete="current-password"
          innerRef={register}
        />
        <span className={"password-show-hide"} onClick={handleToggle}>
          {icon}
        </span>
      </div>
      {errors.password && (
        <span className="text-danger">{errors.password.message}</span>
      )}
    </div>
  );
};

export default PasswordInput;
